import React, { useEffect, useState } from 'react';
import { ChatWindow, LoginForm, PageWrapper } from './components';
import { ANSWERS, ERROR_ANSWER, getCookie } from './Utils';
import { useLogin } from './hooks/useLogin';

function App() {
  const [login, setLogin] = useState(false)
  const hook = useLogin(setLogin);

  const [chatList, setChatList] = useState<any[]>([]);
  const [aiRunning, setAiRunning] = useState(false);
  const [oldChats, setOldChats] = useState<Record<string, any>>({})
  const [currentChatId, setCurrentChatId] = useState<string>('')
  const [reloadChat, setReloadChat] = useState(false);
  const [stop, setStop] = useState(false)
  const [regenerating, setRegenerating] = useState(false);
  const [chatOrder, setChatOrder] = useState<string[]>([])

  const questionHandler = (question: string) => {
    setAiRunning(true);
    let updateChatList = [...chatList, { prompt: question, text: question, id: Math.random() }]
    setChatList(updateChatList)
    const answer = ANSWERS.find((optionAnswer) => optionAnswer.prompt.toLowerCase() === question.toLowerCase());

    const id = Math.random()
    if (answer) setChatList([...updateChatList, { ...answer, id }])

    if (!answer) setChatList([...updateChatList, { ...ERROR_ANSWER, id }])

    if (currentChatId !== undefined && oldChats[currentChatId]) {
      setOldChats({
        ...oldChats,
        [currentChatId]:
          [...updateChatList, answer ? { ...answer, id } : { ...ERROR_ANSWER, id }]
      })

      if (chatOrder[0] !== currentChatId) {
        const indexOfTargetId = chatOrder.indexOf(currentChatId);

        if (indexOfTargetId !== -1) {
          const updatedOrder = [...chatOrder]
          const removedItem = updatedOrder.splice(indexOfTargetId, 1);

          updatedOrder.unshift(removedItem[0]);
          setChatOrder(updatedOrder)
        }
      }
    } else {
      const chatId = (Object.keys(oldChats)).length + 1
      setChatOrder([`${chatId}`, ...chatOrder,]);
      setCurrentChatId(`${chatId}`)
      setOldChats({
        ...oldChats,
        [`${chatId}`]: [...updateChatList, answer ? { ...answer, id } : { ...ERROR_ANSWER, id }],
      })
    }
  }

  const regenerateMessageHandler = () => {
    setAiRunning(true)
    setRegenerating(true)
    const updateChatList = [...chatList];
    const updateItem = updateChatList.pop()
    setChatList([...updateChatList, { ...updateItem, text: updateItem.regenerate, regenerate: updateItem.text }])
  }

  const stopHandler = () => {
    setStop(!stop)
    setAiRunning(false)
  };

  const newChatHander = () => {
    setChatList([])
    setReloadChat(false)
    setCurrentChatId('')
    setAiRunning(false)
  }

  const viewOldChatHandler = (oldChat: any, id: string) => {
    setReloadChat(true)
    setAiRunning(false)
    setCurrentChatId(id)
    setStop(false)
    setRegenerating(false)
    setChatList(oldChat)
  }

  useEffect(() => {
    if (reloadChat) setReloadChat(false)
  }, [chatList])

  useEffect(() => {
    const cookie = getCookie('user')
    setLogin(cookie === 'true')
  }, [])

  return (
    <>
      {login ? (
        <PageWrapper
          newChatHander={newChatHander}
          oldChats={oldChats}
          viewOldChat={viewOldChatHandler}
          chatOrder={chatOrder}
        >
          <ChatWindow
            chatList={chatList}
            handleEnter={questionHandler}
            aiRunning={aiRunning}
            setAiRunning={setAiRunning}
            reloadChat={reloadChat}
            regenerateMessageHandler={regenerateMessageHandler}
            stopHandler={stopHandler}
            stop={stop}
            regenerating={regenerating}
          />
        </PageWrapper>
      ) : (
        <LoginForm
          loginHandler={hook.loginHandler}
          errors={hook.errors}
        />
      )}
    </>
  );
}

export default App;
