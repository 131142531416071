import { Button } from "../Button";

type DefaultQuestionBlockPropTypes = {
  readonly defaultQuestions: string[]
  readonly handleEnter: (value: string) => void
}

export const DefaultQuestionBlock = ({
  defaultQuestions, handleEnter
}: DefaultQuestionBlockPropTypes) => (
  <div className="grid grid-cols-2 gap-2.5 mb-2.5">
    {defaultQuestions.map((question) => (
      <Button
        theme="secondary"
        clickHandler={() => {
          handleEnter(question)
        }}
        key={question}
      >
        {question}
      </Button>
    ))}
  </div>
)