import React from "react"

export const LogoutIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="#F5F5F5" />
  <g id="01 Button">
    <rect x="-16" y="-48" width="219" height="80" rx="12" fill="#262A2D" />
    <g id="Icon + Label">
      <g id="Icon">
        <path id="Icon_2" fillRule="evenodd" clipRule="evenodd" d="M8 17C8 17.5128 7.61396 17.9355 7.11662 17.9933L7 18L5 18C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15L2 5C2 3.40232 3.24892 2.09634 4.82373 2.00509L5 2L7 2C7.55229 2 8 2.44771 8 3C8 3.51284 7.61396 3.93551 7.11662 3.99327L7 4L5 4C4.48716 4 4.06449 4.38604 4.00673 4.88338L4 5L4 15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16L7 16C7.55228 16 8 16.4477 8 17ZM17.7071 10.7071L14.7071 13.7071C14.3166 14.0976 13.6834 14.0976 13.2929 13.7071C12.9024 13.3166 12.9024 12.6834 13.2929 12.2929L14.586 11L7 11C6.44771 11 6 10.5523 6 10C6 9.48716 6.38604 9.06449 6.88338 9.00673L7 9L14.586 9L13.2929 7.70711C12.9024 7.31658 12.9024 6.68342 13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071Z" fill="#F2F2F3" />
      </g>
    </g>
  </g>
</svg>
);
