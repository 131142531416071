  export const createCookie = (name: string, value: string) => {
    const now = new Date();
    const expireTime = new Date();
    expireTime.setTime(now.getTime() + 1000 * 60 * 60);

    document.cookie = `${name}=${value}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
  };

  export const getCookie = (name: string) => {
    try {
      const value = document.cookie;
      const cookie = value.split('; ').filter((cookieValue) => {
        const cookieName = cookieValue.split('=')[0];
        return cookieName === name;
      });
      return cookie[0].split('=')[1];
    } catch (err) {
      return '';
    }
  };
