import React, { ReactNode } from "react";
import { SideMenu } from "../SideMenu";

type PageWrapperPropTypes = {
  readonly children: ReactNode
  readonly newChatHander: () => void
  readonly oldChats: Record<string, any>
  readonly viewOldChat: (chat: any, id: string) => void
  readonly chatOrder: string[]
}

export const PageWrapper = ({ children, newChatHander, oldChats, viewOldChat, chatOrder }: PageWrapperPropTypes) => (
  <div className="grid grid-cols-[274px_auto] border-none font-display">
    <SideMenu
      newChatHander={newChatHander}
      oldChats={oldChats}
      viewOldChat={viewOldChat}
      chatOrder={chatOrder}
    />
    {children}
  </div>
);
