import LOGO_NAME from '../assets/images/moneyLabName.png';
import LOGO_ANI from '../assets/animation/IconAnimantion.gif';
import CHART_14 from '../assets/images/query14Chart.png';
import CHART_15 from '../assets/images/query15Chart.png';
import CHART_16 from '../assets/images/query16Chart.png';
import CHART_17 from '../assets/images/query17Chart.png';

export const IMAGES = new Map();

IMAGES.set('LOGO_NAME', {
  SRC: LOGO_NAME,
  ALT: 'MoneyLab Logo with name',
});

IMAGES.set('CHART_14', {
  SRC: CHART_14,
  ALT: 'Acme Bank asset yield curve chart',
});

IMAGES.set('CHART_15', {
  SRC: CHART_15,
  ALT: 'Acme Bank liability yield curve',
});

IMAGES.set('CHART_16', {
  SRC: CHART_16,
  ALT: 'Acme Bank Yield Curve',
});

IMAGES.set('CHART_17', {
  SRC: CHART_17,
  ALT: 'US Treasury Yield Curve',
});

IMAGES.set('LOGO_ANI', {
  SRC: LOGO_ANI,
  ALT: 'Loading...'
});

