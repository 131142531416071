import React from "react";

type ArrowIconPropTypes = {
  readonly isDisabled: boolean
}

export const ArrowIcon = ({ isDisabled }: ArrowIconPropTypes) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 9.95233C21.9714 10.5539 21.6992 10.9836 21.1836 11.2414L1.97656 19.4914C1.77604 19.5773 1.58984 19.6203 1.41797 19.6203C1.04557 19.6203 0.716146 19.4771 0.429688 19.1906C0.0286458 18.7036 -0.0572917 18.188 0.171875 17.6437L3.35156 11.2844L13.75 9.9953L3.35156 8.70623L0.171875 2.34686C-0.0572917 1.77394 0.0286458 1.25832 0.429688 0.799985C0.859375 0.370298 1.375 0.270037 1.97656 0.499204L21.2266 8.7492C21.7422 9.00702 22 9.40806 22 9.95233Z" fill={isDisabled ? "#BEC0C5" : "#4385FD"} />
  </svg>)
