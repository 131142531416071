import React, { useRef, useState } from "react";
import { DotsIcon, GearIcon, LogoutIcon } from "../Icons";
import { useOutsideClickDetector } from "../../hooks";

export const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useOutsideClickDetector(divRef, () => setIsOpen(false));

  return (
    <div className="relative text-sm" ref={divRef}>
      <button
        className="flex w-8 h-8 px-2 justify-center items-center hover:bg-grey-700 rounded-xl"
        onClick={() => setIsOpen(!isOpen)}
      >
        <DotsIcon />
      </button>
      <div className={`
      absolute bg-grey-700 -top-[100px] rounded-xl px-4 py-3 w-[220px] right-0
        ${isOpen ? 'block' : 'hidden'} ease-linear
      `}
      >
        <div className="flex items-center mb-4">
          <GearIcon />
          <p className="ml-2">Settings</p>
        </div>
        <div className="flex items-center">
          <LogoutIcon />
          <p className="ml-2">Log out</p>
        </div>
      </div>
    </div>
  )
};
