import { useState } from "react";
import { createCookie } from "../Utils";

export const useLogin = (setLogin: any) => {

  const [errors, setErrors] = useState<Record<string, string>>({});

  const loginHandler = async (username: string, password: string) => {
    try {
      setErrors({});
      const errorMessage: Record<string, string> = {};

      if (!username) errorMessage.username = 'Please enter a username';
      if (!password) errorMessage.password = 'Please enter a password';

      if (Object.keys(errorMessage).length) setErrors(errorMessage);

      const response = await fetch(`https://ujh6qh7rz6zkwosane4zndckty0yjput.lambda-url.ca-central-1.on.aws/`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      });

      if (response.status === 401) setErrors({fetch: 'Invalid user or password'})
      if (response.status === 400) setErrors({fetch: 'Invalid user or password'})
      if (response.status === 200) {
        createCookie('user', 'true')
        setLogin(true)
      }
    } catch (error) {
      setErrors({ fetch: 'Something where wrong, please try again' });
    }
  };

  return {
    loginHandler,
    errors,
  };
};
