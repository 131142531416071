import React from "react";
import { THEMES } from "./styles";

type ButtonPropTypes = {
  readonly theme?: 'default' | 'secondary' | 'third' | 'four' | 'five'
  readonly clickHandler: React.MouseEventHandler<HTMLButtonElement>
  readonly children: React.ReactNode
}
export const Button = ({ children, theme = 'default', clickHandler }: ButtonPropTypes) => (
  <button
    onClick={clickHandler}
    className={THEMES[theme]}
  >
    {children}
  </button>
);
