import React, { useState } from 'react';
import { ArrowIcon } from '../Icons/ArrowIcon';

export type InputPropTypes = {
  name: string
  placeholder?: string
  disabled?: boolean
  handleEnter: (event: any) => void
}

export const InputText = ({
  name,
  placeholder = '',
  disabled = false,
  handleEnter,
}: Readonly<InputPropTypes>) => {
  const [value, setValue] = useState('')

  return (
    <div
      title='wrapper'
      className={`
          w-full flex items-center gap-2 rounded-2xl
          border border-solid border-grey-5 px-4 py-3
          focus-within:border-primary-50 overflow-hidden
          cursor-text bg-white-100
          autofill:bg-white-100 active:autofill:bg-white-100
        `}
    >
      <input
        name={name}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleEnter(value);
            setValue('')
          }
        }}
        placeholder={placeholder}
        disabled={disabled}
        className={`
          w-full text-grey-80 border-none
          font-Light text-bodyLarge cursor-text
        placeholder:text-grey-40
          disabled:cursor-not-allowed disabled:bg-white-100
        `}
      />

      <button
        disabled={value.length < 1}
        onClick={(event) => {
          event.stopPropagation();
          handleEnter(value);
          setValue('')
        }}
      >
        <ArrowIcon isDisabled={value.length < 1} />
      </button>
    </div>
  );
};
