import React from "react"

export const AvatarIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_675_137)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 15C0 6.71573 6.71573 0 15 0V0C23.2843 0 30 6.71573 30 15V15C30 23.2843 23.2843 30 15 30V30C6.71573 30 0 23.2843 0 15V15Z" fill="#50545E" />
      <path d="M15.25 14C14.4062 13.9844 13.6953 13.6953 13.1172 13.1328C12.5547 12.5547 12.2656 11.8438 12.25 11C12.2656 10.1562 12.5547 9.44531 13.1172 8.86719C13.6953 8.30469 14.4062 8.01562 15.25 8C16.0938 8.01562 16.8047 8.30469 17.3828 8.86719C17.9453 9.44531 18.2344 10.1562 18.25 11C18.2344 11.8438 17.9453 12.5547 17.3828 13.1328C16.8047 13.6953 16.0938 13.9844 15.25 14ZM15.25 8.75C14.6094 8.76562 14.0781 8.98438 13.6562 9.40625C13.2344 9.82812 13.0156 10.3594 13 11C13.0156 11.6406 13.2344 12.1719 13.6562 12.5938C14.0781 13.0156 14.6094 13.2344 15.25 13.25C15.8906 13.2344 16.4219 13.0156 16.8438 12.5938C17.2656 12.1719 17.4844 11.6406 17.5 11C17.4844 10.3594 17.2656 9.82812 16.8438 9.40625C16.4219 8.98438 15.8906 8.76562 15.25 8.75ZM16.4453 15.125C17.5859 15.1562 18.5391 15.5547 19.3047 16.3203C20.0703 17.0859 20.4688 18.0391 20.5 19.1797C20.5 19.4141 20.4219 19.6094 20.2656 19.7656C20.1094 19.9219 19.9141 20 19.6797 20H10.8203C10.5859 20 10.3906 19.9219 10.2344 19.7656C10.0781 19.6094 10 19.4141 10 19.1797C10.0312 18.0391 10.4297 17.0859 11.1953 16.3203C11.9609 15.5547 12.9141 15.1562 14.0547 15.125H16.4453ZM19.6797 19.25C19.7266 19.25 19.75 19.2266 19.75 19.1797C19.7188 18.2422 19.3984 17.4609 18.7891 16.8359C18.1641 16.2266 17.3828 15.9062 16.4453 15.875H14.0547C13.1172 15.9062 12.3359 16.2266 11.7109 16.8359C11.1016 17.4609 10.7812 18.2422 10.75 19.1797C10.75 19.2266 10.7734 19.25 10.8203 19.25H19.6797Z" fill="white" fillOpacity="0.5" />
    </g>
    <defs>
      <clipPath id="clip0_675_137">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
