export const TABLES = {
  QUERY_1: {
    COLUMNS: ['KPI', '2023 PLAN', 'As at 11/01/2023'],
    KEYS: ['kpi', 'plan', 'date'],
    ROWS: [
      {kpi: 'NIM', plan: '3.50%', date: '4.88%', bgGreen: ['date']},
      {kpi: 'GAP Target', plan: '0.50%', date: '1.50%', bgYellow: ['date']},
      {kpi: 'Nominal Capital Ratio', plan: '10.00%', date: '10.00%'},
      {kpi: 'Tier 1 Capital', plan: '10.00%', date: '6.20%' , bgRed: ['date']},
      {kpi: 'ROE', plan: '15.00%', date: '18.10%'},
    ]
  },

  QUERY_2: {
    COLUMNS: ['ASSETS', "US$'000'000"],
    KEYS: ['assets', 'value'],
    ROWS: [
      {assets: 'Cash and Due From Banks', value: '90', subTitle: true, sectionEnd: true},
      {assets: 'Investments', value: '', subTitle: true},
      {assets: 'US Treasury & agency securities', value: '95'},
      {assets: 'Money market investments', value: '30'},
      {assets: 'Municipal securities', value: '115'},
      {assets: 'Fed Funds Sold & Repos', value: '50'},
      {assets: 'Total Investments', value: '290', sectionEnd: true},

      {assets: 'Loans', value: '', subTitle: true},
      {assets: 'Commercial Loans', value: '424'},
      {assets: 'Consumer Loans', value: '543'},
      {assets: 'Total Loans', value: '424', sectionEnd: true},

      {assets: 'Other Assets', value: '58', subTitle: true, sectionEnd: true},
      {assets: 'TOTAL ASSETS', value: '981', subTitle: true},
    ]
  },

  QUERY_2_SECOND: {
    COLUMNS: ['LIABILITIES AND EQUITY', "US$'000'000"],
    KEYS: ['assets', 'value'],
    ROWS: [
      {assets: 'Due to Banks', value: '20', subTitle: true, sectionEnd: true},
      {assets: 'Deposits', value: '', subTitle: true},
      {assets: 'DDA', value: '95'},
      {assets: 'MMDA', value: '250'},
      {assets: 'Time', value: '310'},
      {assets: 'Public', value: '100'},
      {assets: 'Total Deposits', value: '760', sectionEnd: true},

      {assets: 'Debt', value: '', subTitle: true},
      {assets: '   Fed Funds Purchased and Reverse Repos', value: '0'},
      {assets: '  Other Borrowings', value: '120'},
      {assets: 'Total Debt', value: '120', sectionEnd: true},

      {assets: 'Equity', value: '81',  subTitle: true, sectionEnd: true},
      {assets: 'TOTAL LIABILITIES AND EQUITY', value: '981',  subTitle: true},
    ]
  },

  QUERY_3: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'ASSETS', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Cash and Due From Banks', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "90", total: "90",  sectionEnd: true},
      {assets: 'Investments', week:"", month: "", months: "", halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'US Treasury & agency securities', week:"0", month: "7", months: "36", halfYear: '12', year: "3", years: "37", nonrate: "0", total: "95", },
      {assets: 'Money market investments', week:"0", month: "0", months: "12", halfYear: '18', year: "0", years: "0", nonrate: "0", total: "30", },
      {assets: 'Municipal securities', week:"0", month: "0", months: "7", halfYear: '11', year: "21", years: "76", nonrate: "0", total: "115", },
      {assets: 'Fed Funds Sold & Repos', week:"50", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "50", },
      {assets: 'Total Investments', week:"50", month: "7", months: "55", halfYear: '41', year: "24", years: "113", nonrate: "0", total: "290", sectionEnd: true},
      
      {assets: 'Loans', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Commercial Loans', week:"19", month: "138", months: "29",  halfYear: '47', year: "36", years: "155", nonrate: "0", total: "424"},
      {assets: 'Consumer Loans', week:"3", month: "5", months: "16",  halfYear: '50', year: "45", years: "0", nonrate: "0", total: "119"},
      {assets: 'Total Loans', week:"22", month: "143", months: "45",  halfYear: '97', year: "81", years: "155", nonrate: "0", total: "543", sectionEnd: true},

      {assets: 'Other Assets', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "58", total: "58", subTitle: true, sectionEnd: true},
      {assets: 'TOTAL ASSETS', week:"72", month: "150", months: "100",  halfYear: '138', year: "105", years: "268", nonrate: "148", total: "981", subTitle: true},
    ]
  },

  QUERY_3_SECOND: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'LIABILITIES AND EQUITY', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Due to Banks', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "20", total: "20",  sectionEnd: true},

      {assets: 'Deposits', week:"", month: "", months: "", halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'DDA', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "100", total: "100", },
      {assets: 'MMDA', week:"250", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "250", },
      {assets: 'Time', week:"10", month: "25", months: "50", halfYear: '100', year: "100", years: "25", nonrate: "0", total: "310", },
      {assets: 'Public', week:"100", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "100", },
      {assets: 'Total Deposits', week:"360", month: "25", months: "50",  halfYear: '100', year: "100", years: "155", nonrate: "0", total: "543", sectionEnd: true},

      
      {assets: 'Debt', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Fed Funds Purchased and Reverse Repos', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "0", total: "0"},
      {assets: 'Other Borrowings', week:"100", month: "0", months: "0",  halfYear: '20', year: "0", years: "0", nonrate: "0", total: "120"},
      {assets: 'Total Debt', week:"100", month: "0", months: "0",  halfYear: '20', year: "0", years: "0", nonrate: "0", total: "120", sectionEnd: true},

      {assets: 'Equity', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "81", total: "81", subTitle: true, sectionEnd: true},
      {assets: 'TOTAL LIABILITIES AND EQUITY', week:"460", month: "25", months: "50",  halfYear: '120', year: "100", years: "25", nonrate: "201", total: "981", subTitle: true},
    ]
  },

  QUERY_4: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'ASSETS', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Cash and Due From Banks', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "90", total: "90",  sectionEnd: true},
      {assets: 'Investments', week:"", month: "", months: "", halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'US Treasury & agency securities', week:"0", month: "7", months: "36", halfYear: '12', year: "3", years: "37", nonrate: "0", total: "95", },
      {assets: 'Money market investments', week:"0", month: "0", months: "12", halfYear: '18', year: "0", years: "0", nonrate: "0", total: "30", },
      {assets: 'Municipal securities', week:"0", month: "0", months: "7", halfYear: '11', year: "21", years: "76", nonrate: "0", total: "115", },
      {assets: 'Fed Funds Sold & Repos', week:"50", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "50", },
      {assets: 'Total Investments', week:"50", month: "7", months: "55", halfYear: '41', year: "24", years: "113", nonrate: "0", total: "290", sectionEnd: true},
      
      {assets: 'Loans', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Commercial Loans', week:"19", month: "138", months: "29",  halfYear: '47', year: "36", years: "155", nonrate: "0", total: "424"},
      {assets: 'Consumer Loans', week:"3", month: "5", months: "16",  halfYear: '50', year: "45", years: "0", nonrate: "0", total: "119"},
      {assets: 'Total Loans', week:"22", month: "143", months: "45",  halfYear: '97', year: "81", years: "155", nonrate: "0", total: "543", sectionEnd: true},

      {assets: 'Other Assets', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "58", total: "58", subTitle: true, sectionEnd: true},
      {assets: 'TOTAL ASSETS', week:"72", month: "150", months: "100",  halfYear: '138', year: "105", years: "268", nonrate: "148", total: "981", subTitle: true, sectionEnd: true},

      {assets: 'Average Yield at Duration', week:"3.0%", month: "8.0%", months: "11.0%",  halfYear: '11.0%', year: "9.5%", years: "2.0%", nonrate: "1.0%", total: "",},
      {assets: 'Total Interest Income', week:"2.16", month: "12.00", months: "11.00",  halfYear: '15.18', year: "9.98", years: "5.36", nonrate: "1.48", total: "57.16", subTitle: true, bgBlue: ['total']},
    ]
  },

  QUERY_5: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'LIABILITIES AND EQUITY', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Due to Banks', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "20", total: "20",  sectionEnd: true},

      {assets: 'Deposits', week:"", month: "", months: "", halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'DDA', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "100", total: "100", },
      {assets: 'MMDA', week:"250", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "250", },
      {assets: 'Time', week:"10", month: "25", months: "50", halfYear: '100', year: "100", years: "25", nonrate: "0", total: "310", },
      {assets: 'Public', week:"100", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "100", },
      {assets: 'Total Deposits', week:"360", month: "25", months: "50",  halfYear: '100', year: "100", years: "25", nonrate: "100", total: "760", sectionEnd: true},

      
      {assets: 'Debt', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Fed Funds Purchased and Reverse Repos', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "0", total: "0"},
      {assets: 'Other Borrowings', week:"100", month: "0", months: "0",  halfYear: '20', year: "0", years: "0", nonrate: "0", total: "120"},
      {assets: 'Total Debt', week:"100", month: "0", months: "0",  halfYear: '20', year: "0", years: "0", nonrate: "0", total: "120", sectionEnd: true},

      {assets: 'Equity', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "81", total: "81", subTitle: true, sectionEnd: true},
      {assets: 'TOTAL LIABILITIES AND EQUITY', week:"460", month: "25", months: "50",  halfYear: '120', year: "100", years: "25", nonrate: "201", total: "981", subTitle: true, sectionEnd: true},

      {assets: 'Average Rate at Duration', week:"0.1%", month: "0.1%", months: "2.0%",  halfYear: '3.0%', year: "3.0%", years: "4.0%", nonrate: "0.1%", total: "",},
      {assets: 'Total Interest Expense', week:"0.46", month: "0.03", months: "1.00",  halfYear: '3.60', year: "3.00", years: "1.00", nonrate: "0.20", total: "9.29", subTitle: true, bgBlue: ['total']},
    ]
  },

  QUERY_6: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'Interest Income', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Average Yield at Duration', week:"3.0%", month: "8.0%", months: "11.0%", halfYear: '11.0%', year: "9.5%", years: "2.0%", nonrate: "1.0%", total: ""},
      {assets: 'Total Interest Income', week:"2.16", month: "12.00", months: "11.00", halfYear: '15.18', year: "9.98", years: "5.36", nonrate: "1.48", total: "57.16"},
      {assets: '',  week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "5.83%", sectionEnd: true, subTitle: true},

      {assets: 'Interest Expense', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Average Rate at Duration', week:"0.1%", month: "0.1%", months: "2.0%", halfYear: '3.0%', year: "3.0%", years: "4.0%", nonrate: "0.1%", total: "", },
      {assets: 'Total Interest Expense', week:"0.46", month: "0.03", months: "1.00", halfYear: '3.60', year: "3.00", years: "1.00", nonrate: "0.20", total: "9.29"},
      {assets: '', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "0.95%", sectionEnd: true, subTitle: true},
      
      {assets: 'Net Interest Income', total: "47.87", sectionEnd: true, specialRow: true, subTitle: true, bgBlue: ['total']},
      {assets: 'Net Interest Margin', total: "4.88%", specialRow: true, subTitle: true, bgBlue: ['total']},
    ]
  },

  QUERY_7: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'Interest Income', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Average Yield at Duration', week:"3.0%", month: "8.0%", months: "11.0%", halfYear: '11.0%', year: "9.5%", years: "2.0%", nonrate: "1.0%", total: "", bgBlue: ['week', 'month', 'months', 'halfYear', 'year']},
      {assets: 'Total Interest Income', week:"2.16", month: "12.00", months: "11.00", halfYear: '15.18', year: "9.98", years: "5.36", nonrate: "1.48", total: "57.16"},
      {assets: '', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "5.83%", sectionEnd: true,  subTitle: true},

      {assets: 'Interest Expense', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Average Rate at Duration', week:"0.1%", month: "0.1%", months: "2.0%", halfYear: '3.0%', year: "3.0%", years: "4.0%", nonrate: "0.1%", total: "",  bgBlue: ['week', 'month', 'months', 'halfYear', 'year'] },
      {assets: 'Total Interest Expense', week:"0.46", month: "0.03", months: "1.00", halfYear: '3.60', year: "3.00", years: "1.00", nonrate: "0.20", total: "9.29", sectionEnd: true},
      {assets: '', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "0.95%",  subTitle: true},
      
      {assets: 'Net Interest Margin', total: "4.88%", specialRow: true, subTitle: true, bgBlue: ['total', 'assets']},
    ]
  },

    QUERY_8: {
    HEADER_ROWS: true,
    COLUMNS: [["US$'000'000"], ['1-7', 'Days'], ['8-30', 'Days'], ['31 - 90', 'Days'], ['91 - 180', 'Days'],  ['181 - 365', 'Days'],  ['Over 1', 'Year'] ,  ['Nonrate', 'Sensitive'],  ['', 'TOTAL']],
    KEYS: ['assets', 'week', 'month', 'months', 'halfYear', 'year', 'years', 'nonrate', 'total'],
    ROWS: [
      {assets: 'ASSETS', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'Cash and Due From Banks', week:"0", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "90", total: "90",  sectionEnd: true},
      {assets: 'Investments', week:"", month: "", months: "", halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},
      {assets: 'US Treasury & agency securities', week:"0", month: "7", months: "36", halfYear: '12', year: "3", years: "37", nonrate: "0", total: "95", bgRed: ['years'] },
      {assets: 'Money market investments', week:"0", month: "0", months: "12", halfYear: '18', year: "0", years: "0", nonrate: "0", total: "30", },
      {assets: 'Municipal securities', week:"0", month: "0", months: "7", halfYear: '11', year: "21", years: "76", nonrate: "0", total: "115", bgRed: ['years']  },
      {assets: 'Fed Funds Sold & Repos', week:"50", month: "0", months: "0", halfYear: '0', year: "0", years: "0", nonrate: "0", total: "50", },
      {assets: 'Total Investments', week:"50", month: "7", months: "55", halfYear: '41', year: "24", years: "113", nonrate: "0", total: "290", sectionEnd: true, bgRed: ['years'] },
      
      {assets: 'Loans', week:"", month: "", months: "",  halfYear: '', year: "", years: "", nonrate: "", total: "", subTitle: true},

      {assets: 'Commercial Loans', week:"19", month: "138", months: "29",  halfYear: '47', year: "36", years: "155", nonrate: "0", total: "424"},
      {assets: 'Consumer Loans', week:"3", month: "5", months: "16",  halfYear: '50', year: "45", years: "0", nonrate: "0", total: "119"},
      {assets: 'Total Loans', week:"22", month: "143", months: "45",  halfYear: '97', year: "81", years: "155", nonrate: "0", total: "543", sectionEnd: true},

      {assets: 'Other Assets', week:"0", month: "0", months: "0",  halfYear: '0', year: "0", years: "0", nonrate: "58", total: "58", subTitle: true, sectionEnd: true},
      {assets: 'TOTAL ASSETS', week:"72", month: "150", months: "100",  halfYear: '138', year: "105", years: "268", nonrate: "148", total: "981", subTitle: true},
    ]
  },
};
