import React from "react"

export const AiIcon = () => (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_474_5934)">
    <rect width="30" height="30" rx="15" fill="#18293E" />
    <path d="M14.5658 20.6156H8.38928C6.54146 20.6156 5.39513 18.6045 6.33615 17.0149L9.7938 11.1712C10.7177 9.60959 12.9777 9.60959 13.9016 11.1712L14.936 12.9195L12.5126 17.0149C11.5716 18.6045 12.718 20.6156 14.5658 20.6156Z" fill="#F2F2F2" />
    <path d="M15.3059 20.6158H14.5655C12.7177 20.6158 11.5714 18.6046 12.5124 17.015L14.9357 12.9196L17.359 17.015C18.3001 18.6046 17.1537 20.6158 15.3059 20.6158Z" fill="#79A9FF" />
    <path d="M21.4827 20.6156H15.3062C17.154 20.6156 18.3004 18.6045 17.3593 17.0149L14.936 12.9195L15.9704 11.1712C16.8943 9.60959 19.1543 9.60959 20.0782 11.1712L23.5358 17.0149C24.4769 18.6045 23.3305 20.6156 21.4827 20.6156Z" fill="#4385FD" />
  </g>
  <defs>
    <clipPath id="clip0_474_5934">
      <rect width="30" height="30" fill="white" />
    </clipPath>
  </defs>
</svg>
);
