import React, { ReactNode, useEffect } from "react";
import { AiIcon, AvatarIcon } from "../Icons";
import { useTypingAnimation } from "../../hooks";
import { IMAGES } from "../../Utils";

type MessageWrapperPropTypes = {
  readonly type: 'AI' | 'USER'
  readonly children: ReactNode
  readonly message?: string
  readonly setAiRunning: (aiRunning: boolean) => void
  readonly fastLoad?: boolean
  reloadChat: boolean
  stop: boolean
  stopHandler: () => void
  regenerating: boolean
  scrollHandler?: () => void
}

export const MessageWrapper = ({
  children, type, message, setAiRunning, fastLoad, reloadChat,
  stop, stopHandler, regenerating, scrollHandler,
}: MessageWrapperPropTypes) => {
  const hook = useTypingAnimation(setAiRunning, stop, stopHandler)

  useEffect(() => {
    if (!message) return
    if (reloadChat) {
      hook.setIsLoading(false)
      hook.setText(message)
    } else if (type === 'AI') {
      if (fastLoad) {
        hook.setOriginalString(message);
      } else {
        if (regenerating) {
          hook.setText('')
          hook.setIsLoading(true)
        }
        setTimeout(() => {
          hook.setOriginalString(message)
          return
        }, 1500);
      }
    } else {
      hook.setText(message)
    }
  }, [message])

  useEffect(() => {
    if (hook.isFinished && scrollHandler) {
      scrollHandler()
    }
  }, [hook.isFinished])

  return (
    <div
      className={`
    rounded-3xl text-grey-80 p-4 mb-4 grid grid-cols-[30px_auto] gap-4
    ${type === 'AI' ? 'bg-grey-10' : 'bg-opacity-50'}
    transition ease-in-out delay-150
  `}
    >

      {type === 'AI' ? (<AiIcon />) : (<AvatarIcon />)}
      <div className="pt-[5px]">
        <p className="text-base whitespace-pre-line">{hook.text}</p>

        {type === 'AI' && hook.isLoading && (
          <div className="w-full h-full flex justify-center" >
            <img
              src={IMAGES.get('LOGO_ANI').SRC}
              alt={IMAGES.get('LOGO_ANI').ALT}
              width='60px'
            />
          </div>
        )}
        {hook.isFinished && (
          <>
            {children}
          </>
        )}
      </div>
    </div>
  )
}
