import React from "react";
import { InputText } from "../InputText";
import { Button } from "../Button";
import { DefaultQuestionBlock } from "../DefaultQuestionBlock";
import { Regenerent, StopIcon } from "../Icons";

type ChatUserContainerPropTypes = {
  readonly showButton: boolean
  readonly defaultQuestions: string[]
  readonly handleEnter: (event: any) => void
  regenerateMessageHandler: () => void
  readonly isDisable: boolean
  readonly stopHandler: () => void
}

export const ChatUserContainer = ({
  showButton, defaultQuestions, handleEnter, isDisable,
  regenerateMessageHandler, stopHandler,
}: ChatUserContainerPropTypes) => {
  return (
    <div className="fixed bottom-0 left-[272px] w-[calc(100vw-288px)]">
      <div className="w-[941px] mx-[auto] bg-white-100 pt-4">
        <div className="flex justify-end mb-2.5 bg-white-100">
          {isDisable && (
            <Button theme='four' clickHandler={stopHandler}>
              <StopIcon /> Stop Generating
            </Button>
          )}

          {!showButton && !isDisable && (
            <div className="bg-white-100 rounded-xl">
              <Button theme='five' clickHandler={regenerateMessageHandler}>
                <Regenerent /> Regenerate
              </Button>
            </div>
          )}
        </div>
        <div className="bg-white-100 h-full pb-4 rounded-t-3xl">

          <div className="w-[940px] mx-[auto] p-4 bg-grey-60 rounded-3xl">
            {showButton && (
              <DefaultQuestionBlock defaultQuestions={defaultQuestions} handleEnter={handleEnter} />
            )}
            <InputText
              name="chatName"
              placeholder="Ask me anything…"
              handleEnter={handleEnter}
              disabled={isDisable}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
