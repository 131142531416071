import React from "react";

type TablePropTypes = {
  tableData: {
    COLUMNS: string[] | string[][]
    KEYS: string[]
    ROWS: any[]
  }
  theme: 'default' | 'secondary' | 'horizontal'
}
export const Table = ({ tableData, theme }: TablePropTypes) => {
  return (
    <table
      className={
        `w-full border-separate border-[6px] border-solid border-white-100 rounded-2xl overflow-hidden mt-5 
        ${theme === 'horizontal' ? 'text-xs	' : 'text-base table-fixed'}
      `}
    >
      <thead>
        <tr>
          {tableData.COLUMNS.map((column, index) => (
            <React.Fragment key={Array.isArray(column) ? column[0] : column}>
              <th
                className={`
                border-solid border-white-100 py-2  border-b-[6px] font-semibold leading-normal align-middle text-right
                ${theme === 'horizontal' ? 'px-2.5' : 'px-5'}
                ${index === 0 ? "border-0" : 'border-l-[6px]'}
                ${theme === 'secondary' && index === tableData.COLUMNS.length - 1 ? 'bg-primary-100' : 'bg-grey-0'} 
                ${tableData.COLUMNS.length === 2 && index === 0 ? 'border-r-[6px]' : 'border-0'}
              `}
              >
                {Array.isArray(column) ? (
                  <>
                    {column.map((columnRow) => (
                      <p>{columnRow}</p>
                    ))}
                  </>
                ) : (
                  <>
                    {column}
                  </>
                )}
              </th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.ROWS.map((row, index) => (
          <tr key={`row-${index}`}>
            {tableData.KEYS.map((key, keyIndex) => (
              <React.Fragment key={`row-${index}-${key}`}>
                {row.hasOwnProperty(key) && (
                  <td
                    colSpan={row.specialRow ? 8 : 1}
                    className={`
                    border-solid border-white-100 py-2.5 leading-tight align-middle text-right
                    ${keyIndex === 0 ? 'border-0' : 'border-l-[6px]'}
                    ${theme === 'secondary' && keyIndex === tableData.KEYS.length - 1 ? 'bg-primary-100' : 'bg-grey-0'}
                    ${tableData.KEYS.length === 2 && keyIndex === 0 ? 'border-r-[6px]' : 'border-0'}
                    ${row.sectionEnd ? 'border-b-[6px]' : 'border-0'}
                    ${row.subTitle ? 'font-semibold' : "font-medium"}
                    ${theme === 'horizontal' ? 'px-2.5' : 'px-5'}
                    ${row.bgBlue?.includes(key) ? 'bg-primary-300' : ""}
                    ${row.bgRed?.includes(key) ? 'bg-red-100' : ''}
                    ${row.bgYellow?.includes(key) ? 'bg-yellow-100' : ''}
                    ${row.bgGreen?.includes(key) ? 'bg-green-100' : ''}
                    `}
                  >
                    {row[key]}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table >
  )
}