import React, { useRef } from "react";
import { ChatUserContainer } from "../ChatUserContainer";
import { DEFAULT_QUESTIONS } from "../../Utils";
import { MessageWrapper } from "../MessageWrapper";
import { DefaultQuestionBlock } from "../DefaultQuestionBlock";
import { AllTables } from "../QueryTables";
import { AiMessageFoot } from "../AiMessageFoot";

type ChatWindowPropTypes = {
  chatList: any[]
  handleEnter: (event: any) => void
  aiRunning: boolean
  setAiRunning: (aiRunning: boolean) => void
  regenerateMessageHandler: () => void
  stopHandler: () => void
  reloadChat: boolean
  stop: boolean
  regenerating: boolean
}

export const ChatWindow = ({
  chatList, handleEnter, aiRunning, setAiRunning, stop,
  reloadChat, regenerateMessageHandler, stopHandler,
  regenerating,
}: ChatWindowPropTypes) => {
  const bottomRef = useRef<HTMLInputElement>(null);

  const executeScroll = () => {
    if (bottomRef.current) bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <div className="min-h-screen relative max-h-screen overflow-auto">
      <div className="min-h-[100px] w-[940px] mx-[auto] pt-[131px] pb-[146px]" >

        {chatList.length < 1 && (
          <p className="text-xl text-grey-40">
            How can I help you today?
          </p>
        )}

        {chatList.map((message) => (
          <MessageWrapper
            key={message.id}
            type={message.type}
            message={message.text}
            setAiRunning={setAiRunning}
            reloadChat={reloadChat}
            stop={stop}
            stopHandler={stopHandler}
            regenerating={regenerating}
          >
            {message.image && (
              <img src={message.image.src} alt={message.image.alt} className="mt-4" />
            )}

            {message.table && (
              <AllTables table={message.table} />
            )}

            {message.type === 'AI' && (
              <AiMessageFoot />
            )}
          </MessageWrapper>
        ))}

        {chatList.length > 0 && !aiRunning && (
          <MessageWrapper
            type="AI"
            message="What would you like to do next?"
            setAiRunning={setAiRunning}
            reloadChat={reloadChat}
            stop={stop}
            stopHandler={stopHandler}
            fastLoad
            regenerating={false}
            scrollHandler={executeScroll}
          >
            <div className="mt-4">
              <DefaultQuestionBlock defaultQuestions={DEFAULT_QUESTIONS} handleEnter={handleEnter} />
            </div>
          </MessageWrapper>
        )}

      </div>
      <div ref={bottomRef} className="h-1" />
      <ChatUserContainer
        defaultQuestions={DEFAULT_QUESTIONS}
        showButton={chatList.length < 1}
        handleEnter={handleEnter}
        regenerateMessageHandler={regenerateMessageHandler}
        stopHandler={stopHandler}
        isDisable={aiRunning}
      />
    </div>
  )
};
