import React from "react";
import { TableQuery1 } from "./Query1";
import { TableQuery2 } from "./Query2";
import { TableQuery3 } from "./Query3";
import { TableQuery4 } from "./Query4";
import { TableQuery5 } from "./Query5";
import { TableQuery6 } from "./Query6";
import { TableQuery7 } from "./Query7";
import { TableQuery8 } from "./Query8";

type AllTablesPropTypes = {
  readonly table: string
}

export const AllTables = ({ table }: AllTablesPropTypes) => (
  <div>
    <div className="animate-fade">
      {table === 'query1' && (<TableQuery1 />)}
      {table === 'query2' && (<TableQuery2 />)}
      {table === 'query3' && (<TableQuery3 />)}
      {table === 'query4' && (<TableQuery4 />)}
      {table === 'query5' && (<TableQuery5 />)}
      {table === 'query6' && (<TableQuery6 />)}
      {table === 'query7' && (<TableQuery7 />)}
      {table === 'query8' && (<TableQuery8 />)}
    </div>
  </div>
);
