import { useState } from "react";
import { Button } from "../Button";
import { error } from "console";

type LoginFormPropTypes = {
  readonly loginHandler: (username: string, password: string) => void
  errors: Record<string, string>
}

export const LoginForm = ({ loginHandler, errors }: LoginFormPropTypes) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col gap-16">
      <div className="border border-solid border-grey-40 rounded-xl p-4 min-w-[30%]">
        <div className="mb-6">
          <label htmlFor="username">username</label>
          <div
            title='wrapper'
            className={`
          w-full flex items-center gap-2 rounded-2xl
          border border-solid border-grey-5 px-4 py-3
          focus-within:border-primary-50 overflow-hidden
          cursor-text bg-white-100 mt-3
          autofill:bg-white-100 active:autofill:bg-white-100
        `}
          >
            <input
              name='username'
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              className={`
              w-full text-grey-80 border-none
              font-Light text-bodyLarge cursor-text
            placeholder:text-grey-40
              disabled:cursor-not-allowed disabled:bg-white-100
            `}
            />
          </div>
          {errors.username && (
            <p className="text-red-500 mt-2">{errors.username}</p>
          )}
        </div>

        <label htmlFor="password">Password</label>

        <div className="mb-6">
          <div
            title='wrapper'
            className={`
          w-full flex items-center gap-2 rounded-2xl
          border border-solid border-grey-5 px-4 py-3
          focus-within:border-primary-50 overflow-hidden
          cursor-text bg-white-100 mt-3
          autofill:bg-white-100 active:autofill:bg-white-100
        `}
          >
            <input
              name='password'
              type='password'
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  loginHandler(username, password)
                }
              }}
              className={`
              w-full text-grey-80 border-none
              font-Light text-bodyLarge cursor-text
            placeholder:text-grey-40
              disabled:cursor-not-allowed disabled:bg-white-100
            `}
            />
          </div>
          {errors.password && (
            <p className="text-red-500 mt-2">{errors.password}</p>
          )}
        </div>
        {errors.fetch && (
          <p className="text-red-500 mb-4">{errors.fetch}</p>
        )}
        <Button
          theme="secondary"
          clickHandler={() => loginHandler(username, password)}
        >
          Login
        </Button>
      </div>
    </div>
  )
}
