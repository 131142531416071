import React from "react";
import { IMAGES } from "../../Utils";
import { Button } from "../Button";
import { PlusIcon, UserIcon } from "../Icons";
import { Dropdown } from "../Dropdown";

type SideMenuPropTypes = {
  readonly newChatHander: () => void
  readonly oldChats: Record<string, any>
  readonly viewOldChat: (chat: any, id: string) => void
  readonly chatOrder: string[]
}

export const SideMenu = ({ newChatHander, oldChats, viewOldChat, chatOrder }: SideMenuPropTypes) => {
  const sortChatButtons = () => {
    const orderedList: any[] = [];

    chatOrder.forEach((key) => {
      if (oldChats.hasOwnProperty(key.toString())) {
        orderedList.push(oldChats[key.toString()]);
      }
    });
    return orderedList
  }

  return (
    <div className="w-fill h-screen bg-grey-800 p-8 flex flex-col justify-between text-grey-0">
      <div>
        <img
          src={IMAGES.get('LOGO_NAME').SRC}
          alt={IMAGES.get('LOGO_NAME').ALT}
          className="mb-[60px]"
        />
        <Button clickHandler={newChatHander}>
          <span className="mr-2">
            <PlusIcon />
          </span>
          New Chat
        </Button>

        <div className="mt-5 flex flex-col gap-4">
          <p className="text-grey-80 text-xs font-semibold">Today</p>
          {sortChatButtons().map((chat, index) => (
            <Button
              theme="third"
              clickHandler={() => viewOldChat(chat, chatOrder[index])}
              key={chatOrder[index]}
            >
              <span className="truncate w-[210px]">
                {chat[1]?.buttonLabel}
              </span>
            </Button>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center">
        <div className="
        flex justify-center items-center
        w-[44px] h-[44px] min-w-[44px] rounded-full
        bg-grey-80
      ">
          <UserIcon />
        </div>
        <div className="flex justify-between items-center w-full ml-4">
          <p>Acme Bank</p>
          <Dropdown />
        </div>
      </div>
    </div>
  )
};
