import { useState, useEffect, useRef } from 'react';

export const useTypingAnimation = (aiHandler: (value: boolean) => void, stop: boolean, stopHandler: () => void) => {
  const [text, setText] = useState('');
  const [originalString, setOriginalString] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);

  const intervalIdRef = useRef<any>(null);

  const textWritingHandler = () => {
    let currentIndex = 0;
    setText(originalString[0]);
    intervalIdRef.current = setInterval(() => {
      if (currentIndex + 1 < originalString.length) {
        if (originalString[currentIndex] === undefined) return;

        if (originalString[currentIndex] === '\\' && originalString[currentIndex + 1] === 'n') {
          setText((prevText) => prevText + '\n');
          currentIndex += 2;
        } else {
          setText((prevText) => {
            if (originalString === 'What would you like to do next?' && prevText === 'W' && originalString[currentIndex] === 'W') return prevText + originalString[currentIndex + 1];
            return prevText + originalString[currentIndex];
          });
          currentIndex += 1;
        }
      } else {
        setTimeout(() => {
          aiHandler(false);
          clearInterval(intervalIdRef.current);
          setIsFinished(true);
        }, 1000);
      }
    }, 20);
  };

  useEffect(() => {
    if (!originalString || originalString === text) return;
    setIsLoading(false);

    textWritingHandler();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [originalString]);

  useEffect(() => {
    if (stop) {
      clearInterval(intervalIdRef.current);
      setIsLoading(false);
      stopHandler();
    }
  }, [stop]);

  return {
    text,
    originalString,
    setText,
    setOriginalString,
    isLoading,
    setIsLoading,
    isFinished,
  };
};
