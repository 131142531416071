import { IMAGES } from "./imageList";

export const DEFAULT_QUESTIONS = [ 'Show KPI', 'Calculate Net Interest Income', 'Show Balance Sheet with Duration', 'Run Stress Test']

export const ANSWERS = [
  // query 1
  {
    prompt: 'how are we doing against 2023 plan',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  },
    {
    prompt: 'how are we doing against 2023 plan?',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  }, 
  {
    prompt: 'how are we doing against 2023 forecast',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  }, 
  {
    prompt: 'current key performance indicators',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  }, 
  {
    prompt: 'current kpi',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  }, 
  {
    prompt: 'current kpis',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  },
  {
    prompt: '2023 kpi',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  },
  {
    prompt: 'kpi 2023',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  },
    {
    prompt: 'Show KPI',
    text: "Here are Acme Bank key performance indicators at November 1, 2023",
    regenerate: "Here are Acme Bank key performance indicators at November 1, 2023",
    type: 'AI',
    buttonLabel: '2023 plan',
    table: 'query1'
  },
  // query 2
  {
    prompt: 'what is our current balance sheet',
    text: "Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    regenerate:"Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    type: 'AI',
    buttonLabel: 'Balance sheet',
    table: 'query2'
  },
  {
    prompt: 'display balance sheet',
    text: "Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    regenerate:"Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    type: 'AI',
    buttonLabel: 'Balance sheet',
    table: 'query2'
  },
  {
    prompt: 'show balance sheet',
    text: "Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    regenerate:"Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    type: 'AI',
    buttonLabel: 'Balance sheet',
    table: 'query2'
  },
  {
    prompt: 'show current balance sheet',
    text: "Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    regenerate:"Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    type: 'AI',
    buttonLabel: 'Balance sheet',
    table: 'query2'
  },
  {
    prompt: 'balance sheet',
    text: "Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    regenerate:"Here is Acme Bank balance sheet at November 1, 2023. Total assets are $981 million, total liabilities are $900 million, equity is $81 million.",
    type: 'AI',
    buttonLabel: 'Balance sheet',
    table: 'query2'
  },
  // query 3
  {
    prompt: 'show balance sheet with duration',
    text: "Here is Acme Bank balance sheet by duration at November 1, 2023.",
    regenerate:"Here is Acme Bank balance sheet by duration at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'Interest rates',
    table: 'query3'
  },
  {
    prompt: 'display balance sheet with duration',
    text: "Here is Acme Bank balance sheet by duration at November 1, 2023.",
    regenerate:"Here is Acme Bank balance sheet by duration at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'Interest rates',
    table: 'query3'
  },
  {
    prompt: 'balance sheet duration',
    text: "Here is Acme Bank balance sheet by duration at November 1, 2023.",
    regenerate:"Here is Acme Bank balance sheet by duration at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'Interest rates',
    table: 'query3'
  },
  {
    prompt: 'current balance sheet duration',
    text: "Here is Acme Bank balance sheet by duration at November 1, 2023.",
    regenerate:"Here is Acme Bank balance sheet by duration at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'Interest rates',
    table: 'query3'
  },
    {
    prompt: 'Show Balance Sheet with Duration',
    text: "Here is Acme Bank balance sheet by duration at November 1, 2023.",
    regenerate:"Here is Acme Bank balance sheet by duration at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'Interest rates',
    table: 'query3'
  },
    // query 4
  {
    prompt: 'show interest income',
    text: "Acme Bank total interest income is $57.16 million at November 1, 2023",
    regenerate:"Acme Bank total interest income is $57.16 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest rates',
    table: 'query4'
  },
    {
    prompt: 'show interest income',
    text: "Acme Bank total interest income is $57.16 million at November 1, 2023",
    regenerate:"Acme Bank total interest income is $57.16 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest rates',
    table: 'query4'
  },
    {
    prompt: 'display interest income',
    text: "Acme Bank total interest income is $57.16 million at November 1, 2023",
    regenerate:"Acme Bank total interest income is $57.16 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest rates',
    table: 'query4'
  },
    {
    prompt: 'what is interest income',
    text: "Acme Bank total interest income is $57.16 million at November 1, 2023",
    regenerate:"Acme Bank total interest income is $57.16 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest rates',
    table: 'query4'
  },
    {
    prompt: 'interest income',
    text: "Acme Bank total interest income is $57.16 million at November 1, 2023",
    regenerate:"Acme Bank total interest income is $57.16 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest rates',
    table: 'query4'
  },
  // query 5
  {
    prompt: 'show interest expense',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },
  {
    prompt: 'show interest cost',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },
    {
    prompt: 'what is interest expense',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },  
  {
    prompt: 'what is interest cost',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },  
  {
    prompt: 'interest cost',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },  
  {
    prompt: 'interest expense',
    text: "Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    regenerate:"Acme Bank balance net interest expense is $9.29 million at November 1, 2023",
    type: 'AI',
    buttonLabel: 'Show Interest expense',
    table: 'query5'
  },
  // query 6
    {
    prompt: 'NIM',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'What is NIM',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'what is net interest margin',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'calculate NIM',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'calculate net interest margin',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
    {
    prompt: 'net interest margin',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
    {
    prompt: 'show net interest margin',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
    {
    prompt: 'Show nim',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'NII',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'what is NII',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'what is net interest income',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'calculate NII',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'calculate net interest income',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
      {
    prompt: 'net interest income',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
      {
    prompt: 'show net interest income',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  {
    prompt: 'Show nii',
    text: "Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    regenerate:"Acme Bank Net Interest  income is $47.87 million at November 1, 2023. Acme Bank Net Interest Margin is 4.88% at November 1, 2023.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query6'
  },
  // query 7
      {
    prompt: 'Why is NIM so high',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'Why is NIM higher than plan',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'why is net interest margin so high',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'why is net interest margin higher than plan',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'analyze NIM',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'explain NIM',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'explain net interest margin',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  {
    prompt: 'analyze net interest margin',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
    {
    prompt: 'Calculate Net Interest Income',
    text: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    regenerate: "Net interest margin of 4.88% is 39% higher than Acme Bank 2023 forecast. On the asset side, yields on investments and loans are higher than forecast. On the liability side, interest paid on bank deposits is lower than forecast.",
    type: 'AI',
    buttonLabel: 'NIM',
    table: 'query7'
  },
  //  Query 8
  {
    prompt: 'what assets are at risk if interest rates rise 200 bps',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'what assets are at risk if interest rates increase 200 bps',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'what assets are at risk if interest rates rise 200 basis point',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'what assets are at risk if interest rates increase 200 basis point',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'assets at risk if interest rates rise',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'assets at risk if interest rates increase',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'analyze assets at risk if interest rates rise',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'analyze assets at risk if interest rates increase',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'identify assets at risk if interest rates rise 200 bps',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
    {
    prompt: 'identify assets at risk if interest rates increase 200 bps',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
      {
    prompt: 'run stress test on assets for 200 bps interest rate rise',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
      {
    prompt: 'run stress test on assets for 200 bps interest rate increase',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
  {
    prompt: 'Run Stress Test',
    text: "If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    regenerate:"If interest rates rise 200 basis points, Acme Bank investments maturing in 2025 and beyond and classified as assets held for sale will be re-priced with a loss of $3.4 million. $37 million in US Treasuries will be marked-to-market with a loss of $1.1 million. $76 million in municipal bonds will be marked-to market with a loss of $3.3 million.",
    type: 'AI',
    buttonLabel: 'Interest rates rise',
    table: 'query8'
  },
  // query 9
  {
    prompt: 'what can we do to reduce interest rate risk with long term investment',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses. \n \n Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity. 
    `,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  {
    prompt: 'what can we do to reduce interest rate risk with long term investments',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  {
    prompt: 'reduce interest rate risk with long term investment',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  {
    prompt: 'reduce interest rate risk with long term investments',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  {
    prompt: 'reduce interest rate risk',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  {
    prompt: 'lower interest rate risk',
    text: `Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    regenerate:`Option 1 is to hedge long-dated Treasuries and municipal bonds at a cost of 20 bps. The impact will be to lock in the rate to protect against any future mark-to-market losses.  \n \n
    Option 2 is to sell the entire position of long-dated Treasuries and municipal bonds. The impact will be to book a loss of $12 million now but free up cash to invest at higher yielding loans \n \n
    Option 3 is to do nothing and re-classify long-dated Treasuries and municipal bonds as assets held to maturity. The impact will be to continue to lose NIM of 600 basis points spread over  the next 6 years to maturity.`,
    type: 'AI',
    buttonLabel: 'Interest rates lowers',
  },
  // Query 10
  {
    prompt: 'I want to analyze Acme',
    text: `Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    regenerate:`Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    type: 'AI',
    buttonLabel: 'Analyze Acme',
  },
  {
    prompt: 'I want to analyze acme bank',
    text: `Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    regenerate:`Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    type: 'AI',
    buttonLabel: 'Analyze Acme',
  },
  {
    prompt: 'Tell me about Acme',
    text: `Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    regenerate:`Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    type: 'AI',
    buttonLabel: 'Analyze Acme',
  },
  {
    prompt: 'Tell be about Acme bank',
    text: `Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    regenerate:`Acme Bank is a community bank based in Evanston, Illinois. It is a subsidiary of Acme Bancorp Inc, a publicly traded company on the OTXQX market under the symbol ACME`,
    type: 'AI',
    buttonLabel: 'Analyze Acme',
  },
  // Query 11
  {
    prompt: 'I want to analyze McHenry',
    text: `McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN.  \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN. \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze McHenry',
  },
    {
    prompt: 'I want to analyze McHenry Bank',
    text: `McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN.  \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN. \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze McHenry',
  },
    {
    prompt: 'Tell me about McHenry',
    text: `McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN.  \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN. \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze McHenry',
  },
  {
    prompt: 'Tell me about McHenry Bank',
    text: `McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN.  \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`McHenry Bank is a community bank based in McHenry, Illinois. It is a subsidiary of McHenry Bancorp, Inc., a publicly traded company on the OTCQX market under the symbol MCN. \n \n
    Moneylab analysis of McHenry Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze McHenry',
  },
  // Query 12
    {
    prompt: 'I want to analyze northern trust',
    text: `Founded in Chicago in 1889, Northern Trust Corporation (Nasdaq: NTRS) is a leading provider of wealth management, asset servicing, asset management and banking to corporations, institutions, affluent families and individuals.  \n \n
    Moneylab analysis of Northern Trust is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Founded in Chicago in 1889, Northern Trust Corporation (Nasdaq: NTRS) is a leading provider of wealth management, asset servicing, asset management and banking to corporations, institutions, affluent families and individuals. \n \n
    Moneylab analysis of Northern Trust is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Northern Trust',
  },
  {
    prompt: 'Tell me about northern trust',
    text: `Founded in Chicago in 1889, Northern Trust Corporation (Nasdaq: NTRS) is a leading provider of wealth management, asset servicing, asset management and banking to corporations, institutions, affluent families and individuals.  \n \n
    Moneylab analysis of Northern Trust is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Founded in Chicago in 1889, Northern Trust Corporation (Nasdaq: NTRS) is a leading provider of wealth management, asset servicing, asset management and banking to corporations, institutions, affluent families and individuals. \n \n
    Moneylab analysis of Northern Trust is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Northern Trust',
  },
  // Query 13
  {
    prompt: 'I want to analyze fifth third bank',
    text: `Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2.  \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2. \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Fifth Third Bank',
  },
  {
    prompt: 'Tell me about fifth third',
    text: `Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2.  \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2. \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Fifth Third Bank',
  },
  {
    prompt: 'I want to analyze fifth third',
    text: `Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2.  \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2. \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Fifth Third Bank',
  },
  {
    prompt: 'Tell me about fifth third bank',
    text: `Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2.  \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.
    `,
    regenerate:`Fifth Third Bank is a regional bank headquartered in Cincinnati, Ohio1. It is a subsidiary of Fifth Third Bancorp, a publicly traded company on the NASDAQ market under the symbol FITB2. \n \n
    Moneylab analysis of Fifth Third Bank is based on FDIC Call Report Data for September 30, 2023.`,
    type: 'AI',
    buttonLabel: 'Analyze Fifth Third Bank',
  },
  // query 14
  {
    prompt: 'show asset yield curve',
    text: `Acme Bank asset yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Yield Curve',
    image: {
      src: IMAGES.get('CHART_14').SRC,
      ALT: IMAGES.get('CHART_14').ALT,
    }
  },
    {
    prompt: 'display asset yield curve',
    text: `Acme Bank asset yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Yield Curve',
    image: {
      src: IMAGES.get('CHART_14').SRC,
      ALT: IMAGES.get('CHART_14').ALT,
    }
  },
    {
    prompt: 'asset yield curve',
    text: `Acme Bank asset yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Yield Curve',
    image: {
      src: IMAGES.get('CHART_14').SRC,
      ALT: IMAGES.get('CHART_14').ALT,
    }
  },
  // query 15
    {
    prompt: 'show liability yield curve',
    text: `Acme Bank liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_15').SRC,
      ALT: IMAGES.get('CHART_15').ALT,
    }
  },
  {
    prompt: 'display liability yield curve',
    text: `Acme Bank liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_15').SRC,
      ALT: IMAGES.get('CHART_15').ALT,
    }
  },
  {
    prompt: 'liability yield curve',
    text: `Acme Bank liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_15').SRC,
      ALT: IMAGES.get('CHART_15').ALT,
    }
  },
  // query 16
  {
    prompt: 'show asset and liability yield curve',
    text: `Acme Bank asset liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_16').SRC,
      ALT: IMAGES.get('CHART_16').ALT,
    }
  },
    {
    prompt: 'show asset liability yield curve',
    text: `Acme Bank asset liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_16').SRC,
      ALT: IMAGES.get('CHART_16').ALT,
    }
  },
    {
    prompt: 'display asset and liability yield curve',
    text: `Acme Bank asset liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_16').SRC,
      ALT: IMAGES.get('CHART_16').ALT,
    }
  },
    {
    prompt: 'display asset liability yield curve',
    text: `Acme Bank asset liability yield curve at November 1, 2023`,
    regenerate:`Acme Bank asset liability yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'Asset Liability Yield Curve',
    image: {
      src: IMAGES.get('CHART_16').SRC,
      ALT: IMAGES.get('CHART_16').ALT,
    }
  },
  // query 17
  {
    prompt: 'what is current us treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'show current us treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'display current us treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'what is current treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'show current treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'display current treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'us treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
    {
    prompt: 'treasury yield curve',
    text: `US Treasury yield curve at November 1, 2023`,
    regenerate:`US Treasury yield curve at November 1, 2023`,
    type: 'AI',
    buttonLabel: 'US Treasury Yield Curve',
    image: {
      src: IMAGES.get('CHART_17').SRC,
      ALT: IMAGES.get('CHART_17').ALT,
    }
  },
];

export const ERROR_ANSWER= {
  text: 'Sorry, we are unable to answer your question. Please provide more information on what you are looking for.',
  type: 'AI',
  buttonLabel: 'Clarify request, summarize.',
}